/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vzorníky"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wtm0jyqp6un"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--80 mt--80" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Vzorníky"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":668}} content={"Vážení zákazníci,\nmáme pro Vás k dispozici vzorníky různých druhů podlah. \nV případě zájmu či dotazů ohledně cen a dostupnosti nás neváhejte kontaktovat. S výběrem Vám vždy rádi pomůžeme.<br><br>Vzorníky Vám dovezeme až domů v rámci předem domluveného zaměření.<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/435210abb885460d8ac79e570192afe6_s=660x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/435210abb885460d8ac79e570192afe6_s=350x_.png 350w, https://cdn.swbpg.com/t/19880/435210abb885460d8ac79e570192afe6_s=660x_.png 660w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(9, 9, 38);\">podlahy Kroměříž, podlahy Kromeriz, podlahářské práce Kroměříž, podlaharske prace Kromeriz, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, podlahari Kromeriz, prodej podlah Kroměříž, podlahové studio Kroměříž, podlahy Chalupa Kroměříž, podlahy Chalupa Kromeriz</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}